@import '../functions';
@import '../variables';
@import '../mixins';

.hero.hero-home{
	//@include media-breakpoint-down(md) {
	//	background-image: url('../../img/background/bg-home-v3-new-mobile.jpg');
	//}
	background-image: url('../../img/background/bg-home-v5.jpg');
	position: relative;

	.hero-content{

		@include media-breakpoint-down(sm) {
			height: auto;
			display: flex;
			flex-direction: column;

			.hero-content-title{
				flex: 1 0 auto;
				position: relative;
			}

			.hero-content-graphics{
				flex: 1 0 auto;
			}
		}

		@include media-breakpoint-up(xl) {
			max-width: 1370px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.hero-content-title{
		@include media-breakpoint-only(xs) {margin-top: $spacer * 3;}
		@include media-breakpoint-only(sm) {margin-top: $spacer * 4;}

		h1{
			line-height: 1.2;
			@include media-breakpoint-only(xs) {font-size: rem(32);}
			@include media-breakpoint-only(sm) {font-size: rem(34);}
			@include media-breakpoint-only(md) {font-size: rem(30);}
			@include media-breakpoint-only(lg) {font-size: rem(46);}
			@include media-breakpoint-up(xl) {font-size: rem(46);}
		}

		h2{
			font-weight: $font-weight-light;
			@include media-breakpoint-only(xs) {font-size: rem(22);}
			@include media-breakpoint-only(sm) {font-size: rem(24);}
			@include media-breakpoint-only(md) {font-size: rem(18);}
			@include media-breakpoint-only(lg) {font-size: rem(24);}
			@include media-breakpoint-up(xl) {font-size: rem(28);}
		}
	}



	.hero-content-graphics{
		img{
			max-width: 100%;
			height: auto;
		}

		@include media-breakpoint-only(xs) {
			margin: $spacer * 3 auto;
			width: 80%;
		}

		@include media-breakpoint-only(sm) {
			margin: $spacer * 4 auto;
			width: 75%;
		}

		@include media-breakpoint-up(md) {
			position: absolute;
			text-align: right;
			right: 4vw;
			bottom: $spacer * 7;
			width: 49%;
		}

		@include media-breakpoint-only(lg) {
			right: 4vw;
			bottom: $spacer * 7;
			width: 49%;
		}

		@include media-breakpoint-up(xl) {
			right: 16px;
			bottom: $spacer * 4;
			width: 50%;
		}
	}
}
